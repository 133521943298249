<template>
  <div class="survey">
    <b-form>
      <b-form-group label-cols="3" :label="title">
        <b-row class="form-inner-row">
          <b-col class="form-inner-col">
            <b-form-radio-group v-model="q.answer[0].data">
              <b-form-radio value="예">예</b-form-radio>
              <b-form-radio value="아니오">아니오</b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>
        <b-row class="form-inner-row" :class="q.answer[0].data !== '예' ? 'disabled' : ''">
          <b-col class="form-inner-col">
            <label>{{ q.answer[1].label[0] }}</label>
            <b-form-input type="text" v-model="q.answer[1].data[0]" />
          </b-col>
          <b-col class="form-inner-col">
            <label>{{ q.answer[1].label[1] }}</label>
            <b-form-input type="text" v-model="q.answer[1].data[1]" />
          </b-col>
        </b-row>
        <b-row class="form-inner-row" :class="q.answer[0].data !== '예' ? 'disabled' : ''">
          <b-col class="form-inner-col">
            <label>{{ q.answer[1].label[2] }}</label>
            <b-form-input type="text" v-model="q.answer[1].data[2]" />
            <span>mg/dl</span>
          </b-col>
        </b-row>
        <b-row class="form-inner-row" :class="q.answer[0].data !== '아니오' ? 'disabled' : ''">
          <b-col class="form-inner-col">
            <label>{{ q.answer[2].label }}</label>
            <b-form-input type="text" v-model="q.answer[2].data" class="input-lg"/>
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </div>
</template>
<script>
export default {
  name: 'Q13',
  model: {
    prop: 'q',
  },
  props: ['q'],
  data() {
    return {
      title: '공복혈당 재검 여부',
    };
  },
  updated() {
    if (this.q === '') {
      this.q = {
        title: '공복혈당 재검 여부',
        answer: [
          {
            data: '',
          },
          {
            label: ['날짜', '검진 병원', '결과'],
            data: ['', '', ''],
          },
          {
            label:  '이유',
            data: '',
          },
        ],
      };
    }
    if (this.q.answer[0].data === '아니오') {
      // '아니오' 선택시 '예' 하위의 질문 답변 초기화
      while (this.q.answer[1].data.length) {
        this.q.answer[1].data.pop();
      }
    }
    if (this.q.answer[0].data === '예') this.q.answer[2].data = '';
  },
};
</script>